import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, vShow as _vShow, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-678ca120"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "name"]
const _hoisted_4 = {
  key: 0,
  class: "invalid"
}
const _hoisted_5 = { class: "dropdown-content" }
const _hoisted_6 = ["onMousedown"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", { for: _ctx.id }, _toDisplayString(_ctx.label), 9, _hoisted_2),
    _withDirectives(_createElementVNode("input", _mergeProps({
      id: _ctx.id,
      name: _ctx.name,
      class: ['dropdown-input', { invalid: _ctx.invalid }],
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchFilter) = $event)),
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.focus = true)),
      onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hideOptions, (_ctx.focus = false))),
      onKeydown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.keyMonitor && _ctx.keyMonitor(...args)))
    }, _ctx.$attrs), null, 16, _hoisted_3), [
      [_vModelDynamic, _ctx.searchFilter]
    ]),
    (_ctx.invalid && !_ctx.focus)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.message), 1))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredOptions, (option, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: option.id,
          class: _normalizeClass(['dropdown-item', { active: i === _ctx.arrowCounter }]),
          onMousedown: ($event: any) => (_ctx.selectOption(option))
        }, _toDisplayString(option.name || option.id || "-"), 43, _hoisted_6))
      }), 128))
    ], 512), [
      [_vShow, _ctx.optionsShown]
    ])
  ]))
}