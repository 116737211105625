
import { computed, defineComponent, Ref, ref, watch } from "vue";

type Option = { id: string | number | null; name: string };
const initialOption = { id: null, name: "" };

export default defineComponent({
  name: "Dropdown",
  props: {
    id: {
      type: String,
      required: true,
      default: "dropdown-1",
      note: "Input ID",
    },
    name: {
      type: String,
      required: false,
      default: "dropdown",
      note: "Input name",
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
      note: "Options of dropdown. An array of options with id and name",
    },
    label: {
      type: String,
      required: false,
      default: "",
      note: "Label of dropdown",
    },
    maxItem: {
      type: Number,
      required: false,
      default: 6,
      note: "Max items showing",
    },
    invalid: {
      type: Boolean,
      required: false,
      default: false,
      note: "Is input invalid",
    },
    message: {
      type: String,
      required: false,
      default: "",
      note: "Error message",
    },
    locale: {
      type: String,
      required: false,
      default: "",
      note: "I18N locale",
    },
  },
  setup(props, { emit }) {
    const selected: Ref<Option> = ref(initialOption);
    const focus = ref(false);
    const optionsShown = ref(false);
    const searchFilter = ref("");
    const arrowCounter = ref(0);

    const showOptions = (clear = true) => {
      if (clear) searchFilter.value = "";
      optionsShown.value = true;
    };

    const hideOptions = () => {
      optionsShown.value = false;
    };

    emit("selected", selected.value);

    function mapHelper(item: Option) {
      let word = item.name.toLowerCase();
      if (
        props.locale === "qq-cyr" ||
        props.locale === "kz" ||
        props.locale === "ru"
      ) {
        word = word
          .replace(/ә/i, "а")
          .replace(/ҳ/i, "х")
          .replace(/қ/i, "к")
          .replace(/ө/i, "о")
          .replace(/ғ/i, "г")
          .replace(/ү/i, "у")
          .replace(/ң/i, "н");
      } else {
        word = word
          .replace(/á/i, "a")
          .replace(/ó/i, "o")
          .replace(/ı/i, "i")
          .replace(/ǵ/i, "g")
          .replace(/ú/i, "u")
          .replace(/ń/i, "n");
      }

      return { id: item.id, name: word };
    }

    const filteredOptions = computed(() => {
      const options = props.options as Option[];
      const mappedOptions = options.map(mapHelper);
      const filtered = [];
      const regOption = new RegExp(
        `^${searchFilter.value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")}`,
        "ig"
      );
      for (let i = 0; i < mappedOptions.length; i++) {
        if (
          searchFilter.value.length < 1 ||
          mappedOptions[i].name.match(regOption)
        ) {
          if (filtered.length < props.maxItem) filtered.push(options[i]);
        }
      }

      return filtered;
    });

    const selectOption = (option: Option) => {
      selected.value = option;
      optionsShown.value = false;
      searchFilter.value = selected.value.name;
      emit("selected", selected.value);
    };

    // Selecting when pressing Enter
    const keyMonitor = (e: KeyboardEvent) => {
      if (e.key === "Enter" && filteredOptions.value[arrowCounter.value]) {
        selectOption(filteredOptions.value[arrowCounter.value]);
        emit("selected", filteredOptions.value[arrowCounter.value]);
      }

      if (
        e.key === "ArrowDown" &&
        arrowCounter.value < filteredOptions.value.length - 1
      ) {
        arrowCounter.value += 1;
      }
      if (e.key === "ArrowUp" && arrowCounter.value > 0) {
        arrowCounter.value += -1;
      }
    };

    // watch(searchFilter, () => {
    //   if (filteredOptions.value.length === 0) {
    //     selected.value = initialOption;
    //   } else {
    //     selected.value = filteredOptions.value[arrowCounter.value];
    //   }
    //   emit("filter", searchFilter.value);
    // });

    watch(searchFilter, () => {
      const enteredValue = searchFilter.value.trim();
      if (enteredValue === "") {
        arrowCounter.value = 0;
        return;
      }
      if (filteredOptions.value.length > 1) showOptions(false);
    });

    return {
      focus,
      selected,
      optionsShown,
      searchFilter,
      filteredOptions,
      selectOption,
      showOptions,
      hideOptions,
      keyMonitor,
      arrowCounter,
    };
  },
});
