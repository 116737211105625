import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "datepicker" }
const _hoisted_2 = { for: "date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_datepicker = _resolveComponent("datepicker", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createVNode(_component_datepicker, {
      "model-value": _ctx.modelValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
      id: _ctx.id,
      "input-format": "dd.MM.yyyy",
      "lower-limit": _ctx.lowerLimit,
      style: _normalizeStyle(_ctx.datepickerStyle)
    }, null, 8, ["model-value", "id", "lower-limit", "style"])
  ]))
}