
import { defineComponent, ref } from 'vue'
import Datepicker from "vue3-datepicker";

export default defineComponent({
  name: "TheDatepicker",
  components: { Datepicker },
  props: {
    id: { type: String, required: true },
    label: { type: String, default: '' },
    modelValue: {
      type: Date,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup() {
    const lowerLimit = ref(new Date());

    const datepickerStyle = {
      "--vdp-hover-bg-color": "#fb831a",
      "--vdp-selected-bg-color": "#de6a04",
      "--vdp-elem-font-size": "0.9em",
    };
    
    return { datepickerStyle, lowerLimit };
  },
})
